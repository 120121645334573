import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Select from 'react-select';

function EffectsDropdown(props) {
  const { onChange } = props;

  /* QUERY */
  const data = useStaticQuery(graphql`
    query {
      allSanityEffect {
        nodes {
          _id
          name
          slug {
            current
          }
        }
      }
    }
  `);

  const wanaEffects = data.allSanityEffect.nodes;

  const options = wanaEffects.map((effect, indx) => {
    return { value: effect.slug.current, label: effect.name.toUpperCase() };
  });

  return (
    <Select
      className='effect-dropdown-container'
      classNamePrefix='effect-dropdown'
      isSearchable={false}
      defaultValue={{ value: null, label: 'SELECT' }}
      options={options}
      onChange={onChange}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        outline: 'none',
        outlineWidth: 'transparent',
        outlineStyle: 'none',
        colors: {
          ...theme.colors,
          neutral0: 'transparent',
          neutral90: 'white',
          neutral70: 'white',
          neutral80: 'white',
        },
      })}
    />
  );
}

export default EffectsDropdown;
