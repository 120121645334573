import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Text, Flex, Heading } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import LinkWrapper from '../../components/LinkWrapper';
import CTAButton from '../../components/CTAButton';
import WanaContentCard from '../../components/WanaContentCard';

/* IMPORT APP CONTEXT */
import { AppContext } from '../../context';

/* THIS COMPONENT SHOULD BE UPDATED WHEN WE HAVE THE LEARN SCHEMAS IN SANITY */
function FeaturedContentHome(props) {
  const { wanaKnowMore, mt, postSubcategories, currentPostTitle, postsByLearnSubcategory, inHomePage } = props;
  
  /* QUERY */
  const data = useStaticQuery(graphql`
    query FEATURED_CONTENT_HOME_QUERY {
      allSanityBlogPost(
        sort: {fields: _updatedAt, order: DESC}
        limit: 3
        filter: {isPublic: {eq: true}}
      ) {
        nodes {
          title
          iframe
          resume
          subcategory {
            name
            slug {
              current
            }
          }
          allSubcategories {
            name
            slug {
              current
            }
          }
          mainImage {
            asset {
              gatsbyImageData(width: 570, height: 405, fit: MAX, placeholder: NONE)
            }
          }
          slug {
            current
          }
        }
      }
      allSanityLearnCategory {
        nodes {
          tag
          tagColor {
            color
          }
          postsRoute {
            route
          }
          learnRoute {
            route
          }
        }
      }
    }
  `);

  /* GET TAGS DATA */
  const tagsData = {};
  data.allSanityLearnCategory.nodes.map((item, indx) => {
    tagsData[item.tag] = item;
  });

  const learnRoute = data.allSanityLearnCategory.nodes[0].learnRoute.route;
  // console.log('FeaturedContentHome: ', learnRoute);

  const appContext = React.useContext(AppContext);

  /* BLOG DATA TO USE */
  const [postToDisplay, setPostToDisplay] = React.useState<any[] | null>(null);

  React.useEffect(() => {
    let posts = [];

    if (!postsByLearnSubcategory) {
      /* NO CATEGORY PASSED - FOR HOME AND LEARN PAGES */
      // console.log('USE INFO FROM QUERY');
      posts = data.allSanityBlogPost.nodes;

    } else {
      /* CATEGORY AND SUBCATEGORY PASSED - DATA AVAILABLE IN BLOG PAGECONTEXT - FOR BLOG PAGES */
      posts = postsByLearnSubcategory.nodes.filter(item => item.title !== currentPostTitle);

      // console.log('USE INFO IN PAGECONTEXT ALREADY FILTERED', posts);
    } 

    setPostToDisplay(posts);
  }, []);

  return (
    <Box 
      w="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position='relative' 
      mt={mt || {base:'180px', ms:'160px', msx:'14.5vw', md:'13.2vw', xl:'190px'}} 
      bgColor="grayBG"
      pb={{base:'7.64vw', ms:'7.64vw', md:'110px'}}
      // border="1px"
    >
      {/* SVG TOP IMAGE */}
      <SvgCurve top />

      {/* CONTENT */}
      <Flex
        // w={{base:'298px', msx:'41.92vw', md:'41.92vw', mds:'87vw', lg:'84vw', xl:'1200px'}} 
        w={false ? {base:'90%', msx:'87%', md:'87%', lg:'84%', xl:'1190px'} : {base:'95%', ms:'100%', md:'87vw', mds:'87vw', lg:'84vw', xl:'1200px'}} 
        direction="column"
        // border="1px"
      >
        {/* FEATURED CONTENT HEADING */}
        <Heading
          display={!wanaKnowMore ? 'block' : 'none'}
          color={'primary'}
          fontFamily="Outfit"
          fontSize={{base:'18px'}}
          fontWeight="800"
          letterSpacing={{base:'4.5px'}}
          lineHeight={{base:'30px'}}
          textAlign="center"
          textTransform="uppercase"
          pt={{base:'20vw', ms:'15vw', msx:'10vw', xl:'145px'}}
          pb={{base:'10vw', ms:'7.5vw', msx:'5vw', xl:'71px'}}
        >
          {'featured content'}
        </Heading>

        {/* WANA KNOW MORE HEADING */}
        <Heading
          display={wanaKnowMore ? 'block' : 'none'}
          color={'primary'}
          fontFamily="Outfit"
          fontSize={{base:'44px', ms:'47px', msx:'50px', md:'53px', mds:'56px', lg:'59px', lgs:'62px', xl:'65px'}}
          fontWeight="800"
          letterSpacing={{base:'-0.5px'}}
          lineHeight={{base:'1'}}
          textAlign="center"
          pt={{base:'16vw', ms:'15vw', msx:'13vw', md:'12vw', mds:'11.5vw', lg:'11vw', lgs:'10.77vw', xl:'155px'}}
          mb={{base:'5vw', ms:'5vw', msx:'4vw', md:'3.75vw', mds:'3.75vw', lg:'3.75vw', lgs:'3.75vw', xl:'54px'}}
        >
          {'Wana know more?'}
        </Heading>

        {/* WANA KNOW MORE TEXT */}
        <Text
          w={{base:'320px', ms:'510px', msx:'600px', md:'660px', lg:'739px'}}
          display={'none'}
          color={'primary'}
          fontSize={{base:'20px'}}
          letterSpacing={{base:'0px'}}
          lineHeight={{base:'35px'}}
          textAlign="center"
          pb={{base:'34px', md:'54px'}}
          mx="auto"
          // border="1px"
        >
          {"Let us take you on a deep dive into Wana Quick's fast-acting technology."}
        </Text>

        {/* CONTENT ITEMS */}
        <Flex
          direction={false ? {base:'column', ms:'row'} : {base:'column', msx:'row'}}
          justifyContent={"space-evenly"}
          alignItems={{base:'center', msx:'flex-start'}}
          flexWrap={false ? {base:'nowrap', ms:'wrap'} : {base:'wrap'}}
          style={{zIndex:1}}
          // border="1px"
        >
          {postToDisplay && (
            <>
              {postToDisplay.slice(0,3).map((item, indx) => {
                return (
                  <React.Fragment key={indx}>
                    {item.title !== currentPostTitle && (
                      <WanaContentCard 
                        item={item} 
                        route={data.allSanityLearnCategory.nodes[0].postsRoute.route}
                        allTags={tagsData}
                        inHome={true}
                      />
                    )}
                  </React.Fragment>
                );
              })}

              {/* EMPTY BOX TO FILL BLANK SPACE */}
              {!inHomePage && (
                <Box
                  display={{base:'none', mds:'none'}}
                  w={{base:'83.34vw', ssm:'300px', ms:'43vw', msx:'41.5vw', md:'42vw', mds:'42vw', lg:'40.5vw', lgs:'40.8vw', xl:'570px'}}
                  h="20px"
                  mb={{base:'30px'}}
                  border="1px"
                />
              )}
            </>
          )}
        </Flex>

        {/* VIEW ALL */}
        {inHomePage && (
          <Box
            display={{base:'block'}}
            mx="auto"
            mt={{base:'20px', ms:'22px', msx:'35px'}}
          >
            <CTAButton
              href={learnRoute}
              fontSize={{base:'13px'}}
              bgColor={'primaryDarker'}
              color={'white'}
              width={{base:'148px'}}
              height={{base:'48px'}}
              _hover={{bg:'blue'}}
              ctaText={'view all'}
            />
          </Box>
        )}

      </Flex>

      {/* SVG BOTTOM IMAGE */}
      <SvgCurve top={false} />
    </Box>
  );
}

const SvgCurve = ({ top }) => {
  const position = top ? {top:'-5px'} : {bottom:'-5px'};

  return (
    <Box
      w="100%"
      h={top ? {base:'3%', ms:'4%', msx:'8%', md:'8%', mds:'8%', lg:'8%', lgs:'8%', xl:'8%'} : {base:'2%', md:'10%'}}
      position="absolute"
      left="0"
      style={{zIndex:0, ...position}}
    >
      {/* TOP SVG */}
      {top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            // d="M0.28,122.88 C250.27,141.63 310.10,-85.34 500.27,106.09 L500.00,0.00 L0.00,0.00 Z"
            d="M-0.27,140.63 C250.27,141.63 310.10,-85.34 500.27,106.09 L500.00,0.00 L0.00,0.00 Z"
          >
          </path>
        </svg> 
      )}

      {/* BOTTOM SVG */}
      {!top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            d="M0.00,49.98 C149.99,150.00 330.98,145.56 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          >
          </path>
        </svg> 
      )}
    </Box>
  );
};

const ContentItem = ({ item, route, tagToUse, allTags }) => {
  let tag = null;

  if (!tagToUse) {
    if (!item.iframe) {
      tag = allTags['ARTICLE'];
  
    } else if (item.iframe.startsWith('http')) {
      tag = allTags['VIDEO'];
  
    } else if (!item.iframe.startsWith('http')) {
      tag = allTags['PODCAST'];
  
    }
  }

  return (
    <LinkWrapper 
      href={`${route}${item.slug.current}` || '/'}
    >
      <Box
        w={{base:'298px', ms:'288px', msx:'298px', md:'298px', mds:'42.9vw', lg:'41vw', lgs:'41vw', xl:'585px'}}
        // mx={{base:'auto', md:'0'}}
        mb={{base:'20px', ms:'22px', md:'3.25vw', mds:'1.12vw', lg:'1.95vw', lgs:'2vw', xl:'30px'}}
        display='flex'
        flexDirection={{base:'column', mds:'row'}}
        // border="1px"
      >
        {/* IMAGE */}
        <Flex 
          w={{base:'100%', md:'100%', mds:'18vw', lg:'18vw', lgs:'18vw', xl:'222px'}}
          h={{base:'170px', md:'23.43vw', mds:'18.4vw', xl:'265px'}}
        >
          {item.mainImage.asset && (
            <GatsbyImage image={item.mainImage.asset.gatsbyImageData} alt={'wana blog post'} />
          )}
        </Flex>

        {/* TEXT CONTENT */}
        <Flex
          w="100%"
          maxH={{base:'210px', md:'210px', mds:'18.4vw', xl:'265px'}}
          minH={{base:'210px', md:'210px', mds:'auto'}}
          direction="column"
          alignItems={{base:'center', mds:'flex-start'}}
          pt={{base:'32px', mds:'2vw', lg:'2.7vw', lgs:'3.3vw', xl:'53px'}}
          pl={{mds:'2vw', lg:'2.5vw', lgs:'3.1vw', xl:'50px'}}
          pb={{base:'25px', mds:'0', lg:'0'}}
          bgColor="primaryDarker"
          overflow="hidden"
        >
          {/* CONTENT TYPE */}
          <Flex
            w={{base:'66px'}}
            h={{base:'17px'}}
            bgColor={tagToUse?.tagColor?.color || tag.tagColor.color}
            justifyContent="center"
            alignItems="center"
          >
            <Text
              color='white'
              fontSize={{base:'11px'}}
              fontWeight='bold'
              letterSpacing={{base:'0.92px'}}
              lineHeight={{base:'11px'}}
              textAlign='center'
              textTransform='uppercase'
              // border="1px"
            >
              {tagToUse ? tagToUse.tag : tag.tag}
            </Text>
          </Flex>

          {/* TITLE */}
          <Text
            w={{base:'280px', md:'280px', mds:'27.5vw', lg:'25vw', lgs:'23vw', xl:'330px'}}
            h="auto"
            // h={{base:'100px', md:'100px', mds:'auto', lg:'auto', lgs:'auto', xl:'auto'}}
            color="white"
            fontSize={{base:'25px'}}
            fontWeight='bold'
            letterSpacing={{base:'0'}}
            lineHeight={{base:'40px'}}
            textAlign={{base:'center', mds:'left'}}
            noOfLines={3}
            py={{base:'15px', mds:'0'}}
            mt={{base:'0', mds:'1vw', lg:'1.3vw', xl:'18px'}}
            // whiteSpace={{base:'normal', mds:'nowrap'}}
            // overflow="hidden"
            // textOverflow="ellipsis"
            // border="1px"
            // borderColor="white"
          >
            {item?.title}
          </Text>

          {/* DESCRIPTION */}
          {/* <Text 
            maxW={{base:'90%', ms:'85%', md:'85%', mds:'28vw', lg:'26vw', lgs:'25.5vw', xl:'375px'}}
            h={{base:'30px', msx:'55px', md:'31px', mds:'auto', lg:'auto'}}
            color="white"
            fontSize={{base:'15px', md:'16px', mds:'14px', lg:'16px'}}
            letterSpacing={{base:'0'}}
            lineHeight={{base:'25px', md:'26px', mds:'18px', lg:'26px'}}
            textAlign={{base:'center', mds:'left'}} 
            pb={{base:'5px'}}
            whiteSpace={{base:'normal', mds:'nowrap'}}
            overflow="hidden"
            textOverflow="ellipsis"
            // border="1px"
            // borderColor="white"
          >
            {item?.resume}
          </Text> */}
        </Flex>

      </Box>
    </LinkWrapper>
  );
};

export { FeaturedContentHome, ContentItem, SvgCurve };
