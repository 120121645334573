import * as React from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import EffectsDropdown from '../../components/EffectsDropdown';
import filterTypes from '../../utitlities/filterTypes';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import useWindowSize from '../../utitlities/useWindowSize';

/* IMPORT APP CONTEXT */
import { AppContext } from '../../context';

const isBrowser = typeof window !== "undefined";

function DropdownEffectHome(props) {
  const { onShopPage, pageTopRef } = props;
  const windowSize = useWindowSize();
  // console.log('DropdownEffectHome: ', pageTopRef);
  /* QUERY */
  const data = useStaticQuery(graphql`
    query {
      allSanityEffectsSection {
        nodes {
          header {
            headerText
            textColor {
              color
            }
          }
          description
          dropdownColor {
            color
          }
          mainImage {
            asset {
              gatsbyImageData(placeholder: NONE)
              altText
            }
          }
          parallaxImgs {
            velocity
            image {
              asset {
                url
              }
            }
          }
          shopAllRoute {
            route
          }
        }
      }
    }
  `);
  
  const { header, description, mainImage, parallaxImgs, shopAllRoute } = data.allSanityEffectsSection.nodes[0];
  
  const appContext = React.useContext(AppContext);
  
  const [rightParallaxPosition, setRightParallaxPosition] = React.useState(0);
  const containerRef = React.useRef(null);

  const getRightParallaxPosition = () => {
    if (isBrowser) {
      const windowWidth = window.innerWidth;
      const containerWidth = containerRef.current.clientWidth;
      const rightPosition = (windowWidth - containerWidth) / -2;
      setRightParallaxPosition(rightPosition);
      // console.log(windowWidth, containerWidth, rightPosition);
    }
  };

  React.useEffect(() => {
    getRightParallaxPosition();
  }, []);

  React.useEffect(() => {
    getRightParallaxPosition();
  }, [windowSize.width]);

  const onEffectSelected = (optionSelected) => {
    const effectName = optionSelected.value;
    const link = `${shopAllRoute.route}?${filterTypes.EFFECT}=${effectName}`;
    
    // console.log('onEffectSelected: ', link);

    // if (pageTopRef) {
    //   pageTopRef.current.scrollIntoView();
    // }

    onShopPage ? navigate(`?${filterTypes.EFFECT}=${effectName}`) : navigate(link);
  };

  return (
    <Box
      maxW="100vw"
      h="auto"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      bgColor={onShopPage ? 'grayBG' : 'white'}
      mt={{base:'120px', md:'8.4vw', xl:'120px'}}
      mb={!onShopPage ? '0' : {base:'152px', md:'15px'}}
      overflowX="clip"
    >
      {/* SVG TOP IMAGE */}
      <SvgCurve top />

      {/* CONTENT */}
      <ParallaxProvider>
        <Flex
          ref={containerRef}
          maxW={{base:'100%', lg:'84%', xl:'1200px'}}
          h="max-content"
          pt={{base:'108px', md:'120px'}}
          pb={{base:'142px', md:'120px'}}
          justifyContent='center'
          flexDirection={{ base: 'column', md: 'row' }}
          position="relative"
          mx="auto"
          // border="1px"
        >
          {/* MAIN IMAGE */}
          <Flex 
            px={{base: 0, md:'47px', mds:'57px', lg:'67px', lgs:'77px', xl:'87px'}} 
            w={{base:'82vw', md:'100%'}} 
            mx={{base:'auto', md:'0'}} 
            justifyContent={{ base: 'center', md: 'flex-end' }} 
            style={{zIndex:1}}
          >
            <GatsbyImage image={mainImage.asset?.gatsbyImageData} alt={'people enjoying wana products'} />
          </Flex>

          {/* TEXT / DROPDOWN */}
          <Flex
            w={{base:'100%'}}
            flexDirection='column'
            alignItems={{ base: 'center', md: 'flex-start' }}
            justifyContent='center'
            // py='24px'
            // px={{ base: 0, md: '40px' }}
          >
            {/* HEADER */}
            <Text
              maxW={{base:'71%', ms:'75%', md:'100%', lgs:'90%', xl:'90%'}}
              as='h2'
              color='primary'
              fontSize={{base:'40px', md:'65px'}}
              fontWeight='800'
              lineHeight={{base:'45px', md:'65px'}}
              letterSpacing={{base:'-0.31px', md:'-0.5px'}}
              textAlign={{base:'center', md:'left'}}
              // textTransform='capitalize'
              pt={{base:'88px', md:'20px'}}
              pb={{base:'14px', md:'26px'}}
              // border="1px"
            >
              {header.headerText}
            </Text>

            {/* DESCRIPTION */}
            <Text 
              maxW={{base:'90%', md:'95%', lg:'100%'}}
              color='primary' 
              fontSize={{base:'15px', md:'15px', mds:'16px', lg:'18px', lgs:'20px'}}
              letterSpacing={{base:'0', md:'0'}}
              lineHeight={{base:'1.5', md:'29px', mds:'31px', lg:'33px', lgs:'35px'}}
              textAlign={{base:'center', md:'left'}} 
              pb={{base:'28px', md:'28px'}}
            >
              {description}
            </Text>

            {/* DROPDOWN */}
            <Box 
              w={{base:'217px', md:'200px'}}
              h={{base:'48px', md:'48px'}}
              // mt={{ base: '14px', md: '24px' }} 
              display="flex"
              justifyContent="center"
              alignItems="center"
              role='button' 
              bgColor='primary' 
              zIndex="2"
            >
              <EffectsDropdown onChange={(optionSelected) => onEffectSelected(optionSelected)} />
            </Box>
          </Flex>

          {/* LEFT PARALLAX IMAGE */}
          <Flex
            maxW={{base:'175px', md:'160x'}}
            maxH={{base:'210px'}}
            position="absolute"
            top={{base:'77vw', ms:'65vw', msx:'58vw', md:'22vw', mds:'18vw', lg:'12vw', lgs:'120px', xl:'120px'}}
            left={{base:'-12vw', ms:'-11vw', msx:'-10vw', md:'-1vw', mds:'-1vw', lg:'-6.5vw', lgs:'-6.3vw', xl:'-6vw'}}
            // border="1px"
          >
            <Parallax styleInner={{transition: `${parallaxImgs[0]?.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[30, -20]} tagOuter='figure'>
              <Image 
                src={parallaxImgs[0]?.image.asset.url}
                boxSize={{base:'175px', md:'140px', mds:'145px', lg:'150px', lgs:'155px', xl:'160px'}}
                objectFit="contain"
              />
            </Parallax>
          </Flex>

          {/* RIGHT PARALLAX IMAGE */}
          <Flex
            maxW={{base:'244px', md:'342px'}}
            maxH={{base:'415px'}}
            position="absolute"
            top={{base:'-80px', ms:'-65px', md:'-15%', mds:'-12%', lg:'-17%', lgs:'-12%', xl:'-30px'}}
            right={{base:rightParallaxPosition, xl:'-120px'}}
            zIndex="2"
          >
            <Parallax styleInner={{transition: `${parallaxImgs[1]?.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[30, -20]} tagOuter='figure'>
              <Image 
                src={parallaxImgs[1]?.image.asset.url}
                boxSize={{base:'200px', md:'200px', lg:'250px', xxl:'270px'}}
                objectFit={{base:'cover', xxl:'contain'}}
                objectPosition={{base:'5% 5%', xxl:'initial'}}
              />
            </Parallax>
          </Flex>

          {/* BOTTOM PARALLAX IMAGE */}
          <Flex
            maxW={{base:'147px', md:'227px'}}
            maxH={{base:'147px', md:'227px'}}
            position="absolute"
            bottom={{base:'-2%', md:'-1%', lg:'-5%', xl:'-25px'}}
            left={{base:'40%', md:'38%', mds:'39%', lg:'37%', lgs:'39%', xl:'475px'}}
            zIndex={{base:1, md:1}}
          >
            <Parallax styleInner={{transition: `${parallaxImgs[2]?.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[-7, 32]}>
              <Image 
                src={parallaxImgs[2]?.image.asset.url}
                boxSize={{base:'176px', md:'182px', lg:'227px'}}
                objectFit="contain"
              />
            </Parallax>
          </Flex>
        </Flex>
      </ParallaxProvider>

      {/* SVG BOTTOM IMAGE */}
      <SvgCurve top={false} />
    </Box>
  );
}

const SvgCurve = ({ top }) => {
  const position = top ? {top:'-1px'} : {bottom:'0'};

  return (
    <Box
      w="100%"
      h={top ? {base:'5%', md:'10%'} : {base:'5%', md:'10%'}}
      position="absolute"
      left="0"
      style={{zIndex:0, ...position}}
    >
      {/* TOP SVG */}
      {top && (
        <svg 
          viewBox="0 0 500 149" 
          preserveAspectRatio="none" 
          style={{width:'100%', height:'100%'}}
        >
          <path
            fill="white"
            fillOpacity='1'
            // d="M0.28,84.38 C149.99,150.00 281.88,-99.16 500.27,79.45 L500.00,0.00 L0.00,0.00 Z"
            d="M-0.27,104.13 C250.27,141.63 307.84,-124.81 500.27,106.09 L500.00,0.00 L0.00,0.00 Z"
          >
          </path>
        </svg> 
      )}

      {/* BOTTOM SVG */}
      {!top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            fillOpacity='1'
            d="M0.00,49.98 C149.99,150.00 330.98,145.56 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          >
          </path>
        </svg> 
      )}
    </Box>
  );
};

export default DropdownEffectHome;
